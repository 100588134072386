<template>
  <div class="row justify-content-center">
    <!-- loader -->
    <div id="loader" style="display: none">
      <!--      <img src="assets/img/loading-icon.png" alt="icon" class="loading-icon">-->
    </div>
    <!-- * loader -->

    <!-- App Header -->
    <div class="appHeader no-border transparent position-absolute">
      <div class="left">
        <a href="#" class="headerButton goBack">
          <!--          <ion-icon name="chevron-back-outline"></ion-icon>-->
        </a>
      </div>
      <div class="pageTitle"></div>
      <div class="right">
      </div>
    </div>
    <!-- * App Header -->

    <!-- App Capsule -->
    <div id="appCapsule" class="col-sm-6 col-lg-4 col-xl-4">

      <div class="section mt-2 text-center">
        <h1>Enter Verification Code</h1>
        <h4>OTP has been sent to your registered phone.</h4>
      </div>
      <div class="section mb-5 p-2">

        <form action="index.html">
          <div class="">
            <div class="card-body pb-1">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <input @focus="otpError ? otp = '' : ''" v-model="otp" type="text" class="form-control verification-input" :class="{'is-invalid': otpError}" id="OTP" placeholder="••••••">
                  <i class="clear-input">
                    <!--                    <ion-icon name="close-circle"></ion-icon>-->
                  </i>
                  <div class="input-errors small text-danger " v-for="error of v$.otp.$errors" :key="error.$uid">
                    <div class="error-msg">{{ error.$message }}</div>
                  </div>
                  <div class="input-errors small text-danger " v-if="otpError">
                    <div class="error-msg">Invalid OTP</div>
                  </div>
                </div>
              </div>

              <div class="transparent mb-2 mt-2">
                <button type="submit" class="btn btn-primary btn-block btn-lg" @click.prevent="verifyOtp" :disabled="otp.length !== 6"><b-spinner v-if="isWaitingResponse"></b-spinner><span v-else>Verify</span></button>
              </div>
            </div>
          </div>

        </form>
      </div>

    </div>
    <!-- * App Capsule -->
    <b-modal id="modal-1" centered hide-header-close :hide-footer="true" :hide-header="true" size="lg" title="BootstrapVue">
      <p class="my-4 text-dark text-center">
        The phone number entered is not available in the Police Cooperative Society records.<br>
        If you are an existing member,
        <a class="link" @click.prevent="routeToHome">try again with another phone number.</a><br> or <a class="link" @click.prevent="enrollWithMemberId">Enroll with your Membership ID.</a><span class="mt-1 d-block">If you would like
        to Register as a New Member, Click on <b-button class="link " variant="primary" @click.prevent="routeToRegister">Register as a New Member</b-button></span>
      </p>
    </b-modal>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import useNotyf from "../composable/useNotify";
import { required, minLength } from '@vuelidate/validators'
export default {
  name: 'OtpVarification',
  data() {
    return {
      otp: '',
      phone_number: null,
      isWaitingResponse: false,
      otpError: false
    }
  },
  watch:{
    'otp': function () {
      if(this.otpError) this.otpError = false
    }
  },
  validations () {
    return {
      otp: {
        required,
        minLength: minLength(6)
      }
    }
  },
  methods: {
    verifyOtp() {
      this.v$.$validate()
      if(this.v$.$error) return
      this.isWaitingResponse = true
      this.$axios.post('otp-submit', {
        phone_number: this.$route.query.phone,
        otp: this.otp
      }).then(({data}) => {
        this.isWaitingResponse = false
        if(data.status === 0) {
          this.otpError = true
          useNotyf().error(data.message)
        } else if(data.status === 1 && !data.data.enrollment_url) {
          this.openModal()
        } else {
          window.location.href = data.data.enrollment_url
        }
      })
    },
    openModal() {
      this.$bvModal.show('modal-1')
    },
    routeToHome() {
      this.$router.push({
        path: '/'
      })
    },
    routeToRegister () {
      this.$router.push({
        path: '/member-registration',
        query: {
          phone: this.$route.query.phone
        }
      })
    },
    enrollWithMemberId() {
      this.$router.push({
        path: '/enroll-with-membership-id',
        query: {
          phone: this.$route.query.phone
        }
      })
    }
  },
  setup: () => {
    return { v$: useVuelidate() }
  }
}
</script>

<style scoped lang="scss">
.verification-input {
  font-size: 32px !important;
  letter-spacing: 10px;
  text-align: center;
  border-radius: 10px !important;
  border: 1px solid #DCDCE9 !important;
  width: 195px !important;
  padding: 0 10px !important;
  margin: auto;
  min-height: 70px !important;
  font-weight: 700;
  color: #27173E !important;
  box-shadow: none !important;
  background: #FFF !important;
  &.is-invalid {
    border: 1px solid $colorDanger !important;
    color: $colorDanger !important;
  }
}
.link {
  cursor: pointer;
}
</style>
